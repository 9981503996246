<template>
  <section>
    <div class="content-header">
      <h2>Works cited </h2>
    </div>
    <div class="content-wrapper">
      <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
      <ul>
        <li class="pb-3">Mishra, P. & McDonald, K. (2017). Career Resilience: An Integrated Review of the Empirical Literature. <em>Human Resource Development Review, Vol. 16</em>(3) 207–234. <a href="https://journals.sagepub.com/doi/full/10.1177/1534484317719622" target="_blank">https://journals.sagepub.com/doi/full/10.1177/1534484317719622</a> </li>
        <li class="pb-3">Centennial College. (2021). <em>Career Resilience Toolkit</em>.<br> <a href="https://www.centennialcollege.ca/student-life/career-services-and-co-operative-education/career-resilience/about-the-career-resilience-toolkit/" target="_blank">www.centennialcollege.ca/student-life/career-services-and-co-operative-education/career-resilience/about-the-career-resilience-toolkit/</a></li>
        <li class="pb-3">Mitchell, K.E.; Levin, A.S.; Krumboltz, J.D. (1999). Planned happenstance: Constructing unexpected career opportunities. <em>Journal of Counseling and Development, Vol77</em>, No. 2: 115-124. DOI:10.1002/j.1556-6676.1999.tb02431.x</li>
        <li class="pb-3">Hirschi, A. (2012). The career resources model: an integrative framework for career counsellors. <em>British Journal of Guidance & Counselling, Vol. 40</em>, No. 4: 369-383. <a href="https://www.tandfonline.com/doi/abs/10.1080/03069885.2012.700506" target="_blank">https://www.tandfonline.com/doi/abs/10.1080/03069885.2012.700506</a></li>
        <li class="pb-3"><em>Career resources for Indigenous students, students of colour, students with disabilities and LGBTQ2SIA+ students</em>. (2021, May 10). Student Services.<br> <a href="https://students.ubc.ca/career/career-resources/career-resources-indigenous-colour-disabilities-lgbtq2sia" target="_blank">students.ubc.ca/career/career-resources/career-resources-indigenous-colour-disabilities-lgbtq2sia</a> </li>
        <li class="pb-3">University of Toronto. (2021). <em>Resources for International Students | Career Centre</em>. Student Services. <a href="https://www.utm.utoronto.ca/careers/about-us/services-resources/resources/resources-international-students" target="_blank">www.utm.utoronto.ca/careers/about-us/services-resources/resources/resources-international-students</a></li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
